<template>
    <div class="invitedDetail">
        <div class="qrcode">
            <div id="qrcode" class="qrcodeBox" ref="qrcode">
                <!-- <img alt="Scan me!" :src="info.apply_url" style="display: block;height: 220px;display: flex;align-items: center;justify-content: center;"> -->
            </div>
            <div style="color:#969799;margin-bottom:10px">(提示:老师请勿扫描二维码)</div>
        </div>
        <div>
            <van-cell-group>

                <van-cell title-style='width:70px;flex: inherit;' title="收费校区" :value="studio.studio_name" />
                <van-cell title="课程顾问" :value="course_consultant.course_consultant_name" />
                <van-cell title="助单教练" :value="suport_consultant.course_consultant_name" v-if="suport_consultant" />
                <van-cell title-style='width:70px;flex: inherit;' title="收费名称" :value="fee.fee_title" />
                <van-cell title="有效课时" :value="fee.times" />
                <van-cell title="赠送课时" :value="fee.gift_times?fee.gift_times:0" />

                <van-cell title="订单金额" :value="fee.fee_price" />
                <van-cell title="优惠金额" :value="coupon.coupon_dis_amount?coupon.coupon_dis_amount:0" />
                
                <van-cell title="" >
                    <template #default>
                        <span>应付¥ {{info.charge_money}}</span>
                    </template>
                </van-cell>
            </van-cell-group>
        </div>
        <loadings :loading='loading' />
    </div>
</template>

<script>
    import QRCode from 'qrcodejs2';
    import { Cell, CellGroup } from 'vant'
    import Loadings from '@/components/loading'
    export default {
        name:'huidongInvitedDetail',
        components: {
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            loadings:Loadings
        },
        data() {
            return {
                loading:false,
                apply_id: this.$route.query.apply_id,
                info:{},
                studio:{},
                course_consultant:{},
                suport_consultant:{},
                fee:{},
                coupon:{}
            }
        },
        mounted(){
            this.getDetail()
        },
        methods:{
            async getDetail(){
                this.loading = true
                await this.$api.custom_huidong_apply_detail({apply_id:this.apply_id})
                .then(res=>{
                    this.loading = false
                    this.info = res.data
                    this.studio = res.data.studio
                    this.course_consultant = res.data.course_consultant
                    this.suport_consultant = res.data.suport_consultant
                    this.fee = res.data.fee
                    this.coupon = res.data.coupon

                    this.$nextTick(() => {
                        var canvas = this.$refs.qrcode
                        new QRCode(canvas, {
                            text:res.data.apply_url, 
                            width:200,
                            height:200,
                            colorDark : "#000",   //二维码颜色
                            colorLight : "#ffffff"   //二维码背景色
                        })
                    })
                })
                .catch(err=>{
                    this.loading = false
                })
            }
        }
    }
</script>

<style lang="less">
    .invitedDetail{
        .qrcode{
            background: #fff;
            // height: 220px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction:column;
            padding-top: 10px;
            padding-bottom: 5px;
        }
    }
</style>